<!-- Confirmation of email address after registration of stream user on portal -->

<template>
    <v-card class="pa-3" max-width="800">
        <div v-if="ready">
            <div v-if="success">
                <h1>{{ message }}</h1>
                <p>
                    {{ $t("confirmEmail.success.text") }}
                </p>
            </div>
            <div v-if="!success">
                <h1>{{ $t("confirmEmail.fail.title") }}</h1>
                <p>
                    {{ $t("confirmEmail.fail.text") }}
                </p>
            </div>
        </div>
    </v-card>
</template>

<script>
import Vue from "vue";
import {ILLI_API_SERVER_URL } from "../config";
import axios from "axios";

export default Vue.extend({
    data() {
        return {
            success: false,
            message: null,
            ready: false,
        };
    },
    created() {
        const token = this.$route.params.token;

        axios.post(`${ILLI_API_SERVER_URL}/stream/account/open/activate`, { token })
        .then((response) => {
            console.log({response});
            this.success = true;
            this.message = response.data.message;
        })
        .catch((error) => {
            console.log({error});
            this.success = false;
        })
        .finally(() => {
            this.ready = true;
        })
    },
})

</script>

<style scoped>



</style>